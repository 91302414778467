









































































































































import { Component, ProvideReactive, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Api } from "@/api";
import {
  AreYouSureModal,
  CompanyDetailsModal,
  InfoModal,
  PaymentCardDetailsModal,
  PricingCard,
} from "@/components";
import { namespace as authNs } from "@/store/auth";
import { CompanyDetails, PlanStatus, PlanType } from "@/types";

@Component({
  components: {
    AreYouSureModal,
    CompanyDetailsModal,
    InfoModal,
    PaymentCardDetailsModal,
    PricingCard,
  },
})
export default class ChangeSubscription extends Vue {
  @Getter("plan", { namespace: authNs }) currentPlan!: PlanType;
  private planToSwitch: PlanType = PlanType.NotSelected;
  private disableApiInfo = "";

  private loading = true;
  private loadingPaymentRequest = false;

  @ProvideReactive("disable-select")
  private disabledSelect = false;

  private areYouSureModalVisible = false;
  private companyDetailsModalVisible = false;
  private paymentCardDetailsModalVisible = false;
  private paymentInfoModalVisible = false;

  private setupClientSecret = "";

  private async mounted(): Promise<void> {
    const { status, setupClientSecret } = await Api.getSubscription();
    if (
      status === PlanStatus.PENDING_AUTHORIZATION ||
      status === PlanStatus.REQUIRES_AUTHORIZATION_AT_PAYMENT_PROVIDER
    ) {
      this.showPaymentDetailsModalOrConfirmChange(setupClientSecret);
    }
    this.loading = false;
  }

  private showAreYouSureModal(plan: PlanType): void {
    this.addApiInfoIfNecessary(plan);
    this.planToSwitch = plan;
    this.areYouSureModalVisible = true;
  }

  private addApiInfoIfNecessary(plan: PlanType): void {
    this.disableApiInfo = "";
    if (this.currentPlan !== PlanType.NotSelected && plan === PlanType.Test) {
      this.disableApiInfo =
        "The API of your organizations on the Hydra Mainnet will be automatically disabled. There is no loss of data and the organizations can be re-enabled when you convert back to a paid plan. Devnet organizations are not impacted.";
    }
  }

  private async planConfirmed(): Promise<void> {
    this.disabledSelect = true;
    this.areYouSureModalVisible = false;

    if (this.planToSwitch !== PlanType.Test) {
      this.companyDetailsModalVisible = true;
    } else {
      await this.switchToFreePlan();
    }
    this.disabledSelect = false;
  }

  private async switchToFreePlan(): Promise<void> {
    try {
      await Api.changeSubscriptionPlan({
        plan: PlanType.Test,
      });
      this.storePlanAndRedirect();
    } catch (error) {
      console.error(error);
    }
  }

  private async switchToPaidPlan(
    companyDetails: CompanyDetails
  ): Promise<void> {
    this.loadingPaymentRequest = true;
    try {
      const { setupClientSecret } = await Api.changeSubscriptionPlan({
        companyDetails,
        plan: this.planToSwitch,
      });

      this.showPaymentDetailsModalOrConfirmChange(setupClientSecret);
    } catch (error) {
      this.companyDetailsModalVisible = false;
      console.error(error);
    }
    this.loadingPaymentRequest = false;
  }

  private showPaymentDetailsModalOrConfirmChange(
    setupClientSecret: string | null
  ): void {
    this.companyDetailsModalVisible = false;

    if (setupClientSecret) {
      this.paymentCardDetailsModalVisible = true;
      this.setupClientSecret = setupClientSecret;
      return;
    }
    this.paymentInfoModalVisible = true;
  }

  private storePlanAndRedirect(): void {
    this.$store.dispatch(`${authNs}/storePlan`, { plan: this.planToSwitch });
    this.$router.push({ name: "dashboard" });
  }

  private onPaymentConfirmationCompleted(): void {
    this.paymentInfoModalVisible = true;
  }

  private PlanType: typeof PlanType = PlanType;
}
